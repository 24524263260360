<template>
<div>
    <div id="fake-container" v-if="user === '' && this.$route.name != 'signin' && this.$route.name != 'password'"></div>
    <div id="navigator-wrap" v-if="this.$route.name != 'signin' && this.$route.name != 'password'">
        <b-container id="hamburger-container" align-v="center">
            <b-img id="nav-members-background" v-if="this.$route.name === 'members'" src="../assets/Member_Banner.png"></b-img>
            <b-link id="hamburger" @click="$bvModal.show('menu-modal')">
                <b-img id="ham-online" v-if="offline === false" src="../assets/Hamburger.png"></b-img>
                <b-img id="ham-offline" v-if="offline === true" src="../assets/HamburgerOffline.png"></b-img>
            </b-link>
            <b-link id="search-button" @click.prevent="goMembers">
                <button v-if="(this.showsearch === 'no') && (this.$route.name === 'members')" class="btn searchbutton">
                    <font-awesome-icon icon="search"></font-awesome-icon>
                </button>
            </b-link>
            <b-link id="search-shop-button" @click.prevent="goShops">
                <button v-if="(this.showshopsearch === 'no') && (this.$route.name === 'shops')" class="btn searchbutton">
                    <font-awesome-icon icon="search"></font-awesome-icon>
                </button>
            </b-link>
        </b-container>


        <b-modal no-fade id="menu-modal">
            <template v-slot:modal-header="">
                <b-container fluid>
                    <b-row align-h="center">
                        <b-img id="menu-logo" src="../assets/Menu_logo.png"></b-img>
                    </b-row>
                    <b-row align-h="center">
                        <div class="pt-2">
                            {{ user.name }}
                        </div>
                    </b-row>
                </b-container>
            </template>

            <template v-slot:default="">
                <b-container fluid id="menu-middle">
                    <b-link id="home-link" href="#" @click.prevent="goHome">
                        <b-row class="px-0 py-0 mb-2">
                            <div align-v="center">
                                <b-icon-house-fill class="ml-2 mr-2 icon-grid"></b-icon-house-fill>
                            </div>
                            <div class="link-grid">HOME</div>
                        </b-row>
                    </b-link>
                    <div v-if="Object.keys(user).length > 0">
                        <b-link v-if="user.member_access === '1'" id="members-link" href="#" @click.prevent="goMembers">
                            <b-row class="px-0 py-0 mb-2" align-v="center">
                                <b-img class="ml-2 mr-2 icon-grid icon-grid-png" src="../assets/Menu_Item_Member.png"></b-img>
                                <div class="link-grid">MEMBERS</div>
                            </b-row>
                        </b-link>
                        <b-link v-if="user.employer_access === '1'" id="shops-link" href="#" @click.prevent="goShops">
                            <b-row class="px-0 py-0 mb-2" align-v="center">
                                <b-img class="ml-2 mr-2 icon-grid icon-grid-png" src="../assets/Menu_Item_Shop.png"></b-img>
                                <div class="link-grid">SHOPS</div>
                            </b-row>
                        </b-link>
                        <b-link id="noteshistory-link" href="#" @click.prevent="goNotesHistory">
                            <b-row class="px-0 py-0 mb-2" align-v="center">
                                <b-img class="ml-2 mr-2 icon-grid icon-grid-png" src="../assets/Notes_History.png"></b-img>
                                <div class="link-grid">NOTES HISTORY</div>
                            </b-row>
                        </b-link>
                        <b-link id="visitationlog-link" href="#" @click.prevent="goVisitationLog">
                            <b-row class="px-0 py-0 mb-2" align-v="center">
                                <b-icon-clipboard class="ml-2 mr-2 icon-grid"></b-icon-clipboard>
                                <div class="link-grid">VISITATION LOG</div>
                            </b-row>
                        </b-link>
                    </div>
                    <div id="offline-msg" v-if="offline">
                        No Internet Connection.<br>App is in offline mode.
                    </div>
                </b-container>
            </template>

            <template v-slot:modal-footer="">
                <b-container fluid>
                    <b-link v-if="Object.keys(user).length > 0" id="logout-link" href="#" @click.prevent="signOut">
                        <b-row align-v="center">
                            <b-img class="mr-2" src="../assets/Menu_Item_LogOut.png"></b-img>
                            <div>Logout</div>
                        </b-row>
                    </b-link>
                    <b-link v-if="Object.keys(user).length === 0" id="signin-link" href="#" @click.prevent="signIn">
                        <b-row align-v="center">
                            <b-img class="mr-2" src="../assets/Menu_Item_SignIn.png"></b-img>
                            <div>Login</div>
                        </b-row>
                    </b-link>
                </b-container>

            </template>

        </b-modal>
    </div>
</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    props: {

    },

    computed: {
        ...mapGetters({
            offline: 'loadingmsg/offline',
            authenticated: 'auth/authenticated',
            user: 'auth/user',
            showsearch: 'members/showsearch',
            showshopsearch: 'shops/showshopsearch',
        })
    },

    components: {},

    methods: {
        ...mapActions({
            signOutAction: 'auth/signOut',
            setShowSearch: 'members/setShowSearch',
            setShowShopSearch: 'shops/setShowShopSearch',
            setHideSearch: 'members/setHideSearch',
            hideShowSearch: 'members/hideShowSearch',
            clearMemberHistory: 'members/clearMemberHistory',
            clearShopHistory: 'shops/clearShopHistory',
            setNoteHistoryScope: 'noteshistory/setNoteHistoryScope',
            getNotesHistory: 'noteshistory/getNotesHistory',
            getVisitationLog: 'visitationlog/getVisitationLog',
            getFilterOptions: 'visitationlog/getFilterOptions',
            setDateFilter: 'visitationlog/setDateFilter',
            setError: 'visitationlog/setError',
            turnOnLoadingMsg: 'loadingmsg/turnOnLoadingMsg',
            turnOffLoadingMsg: 'loadingmsg/turnOffLoadingMsg'
        }),

        signIn() {
            // this.clearMemberHistory();
            // this.clearShopHistory();
            this.signOutAction().then(() => {
                this.$router.replace({
                    name: 'signin'
                }).then(() => {
                    this.$bvModal.hide('menu-modal')
                }) //.catch(() => { console.log('signed out'); })
            }) //.catch(() => { console.log('signed out'); })
        },

        signOut() {
            this.clearMemberHistory();
            this.clearShopHistory();
            this.signOutAction().then(() => {
                this.$router.replace({
                    name: 'signin'
                }).then(() => {
                    this.$bvModal.hide('menu-modal')
                }) //.catch(() => { console.log('signed out'); })
            }) //.catch(() => { console.log('signed out'); })
        },

        goHome() {
            if (this.$route.name != 'home') {
                this.turnOnLoadingMsg().then(() => {
                    // this.hideShowSearch().then(() => {
                    this.$router.replace({
                        name: 'home'
                    }).then(() => {
                        this.$bvModal.hide('menu-modal')
                        window.scrollTo({ top: 0 });
                        this.turnOffLoadingMsg();
                    })
                    this.turnOffLoadingMsg();
                })

            } else {
                this.$bvModal.hide('menu-modal')
                window.scrollTo({ top: 0 });
            }

        },

        goMembers() {
            if (this.$route.name != 'members') {
                this.turnOnLoadingMsg().then(() => {
                    this.setShowSearch().then(() => {
                        this.$router.replace({
                            name: 'members'
                        }) //.catch(e => { console.log(e); })

                    }).then(() => {
                        this.$bvModal.hide('menu-modal');
                        window.scrollTo({ top: 0 });
                        this.turnOffLoadingMsg();

                    }) //.catch(e => { console.log(e); })

                })
            } else {
                this.turnOnLoadingMsg().then(() => {
                    this.setShowSearch().then(() => {
                        this.$bvModal.hide('menu-modal');
                        window.scrollTo({ top: 0 });
                        this.turnOffLoadingMsg();

                    }) //.catch(e => { console.log(e); })

                }) //.catch(e => { console.log(e); })
            }
        },

        goShops() {
            if (this.$route.name != 'shops') {
                this.turnOnLoadingMsg().then(() => {
                    this.setShowShopSearch().then(() => {
                        this.$router.replace({
                            name: 'shops'
                        }).then(() => {
                            this.$bvModal.hide('menu-modal');
                            window.scrollTo({ top: 0 });
                            this.turnOffLoadingMsg();

                        }) //.catch(e => { console.log(e); })
                    }) //.catch(e => { console.log(e); })
                }) //.catch(e => { console.log(e); })


            } else {
                this.turnOnLoadingMsg().then(() => {
                    this.setShowShopSearch().then(() => {
                        this.$bvModal.hide('menu-modal');
                        window.scrollTo({ top: 0 });
                        this.turnOffLoadingMsg();

                    }) //.catch(e => { console.log(e); })

                }) //.catch(e => { console.log(e); })
            }
        },

        goNotesHistory() {
            if (this.$route.name != 'noteshistory') {
                this.turnOnLoadingMsg().then(() => {
                    this.setNoteHistoryScope('30');
                    this.getNotesHistory(this.user).then(() => {
                        this.$router.replace({
                            name: 'noteshistory'
                        }).then(() => {
                            this.$bvModal.hide('menu-modal');
                            window.scrollTo({ top: 0 });
                            this.turnOffLoadingMsg();

                        }) //.catch(e => { console.log(e); })
                    }) //.catch(e => { console.log(e); })
                }) //.catch(e => { console.log(e); })


            } else {
                this.turnOnLoadingMsg().then(() => {
                    this.$bvModal.hide('menu-modal');
                    window.scrollTo({ top: 0 });
                    this.turnOffLoadingMsg();

                }) //.catch(e => { console.log(e); })
            }
        },

        goVisitationLog() {
            if (this.$route.name != 'visitationlog') {
                this.turnOnLoadingMsg().then(() => {
                    this.setDateFilter('');
                    this.setError('');
                    this.getVisitationLog().then(() => {
                        this.getFilterOptions();
                        this.$router.replace({
                            name: 'visitationlog'
                        }).then(() => {
                            this.$bvModal.hide('menu-modal');
                            window.scrollTo({ top: 0 });
                            this.turnOffLoadingMsg();

                        }) //.catch(e => { console.log(e); })
                    }) //.catch(e => { console.log(e); })
                }) //.catch(e => { console.log(e); })


            } else {
                this.turnOnLoadingMsg().then(() => {
                    this.$bvModal.hide('menu-modal');
                    window.scrollTo({ top: 0 });
                    this.turnOffLoadingMsg();

                }) //.catch(e => { console.log(e); })
            }
        },


    }
}
</script>

<style scoped>
#fake-container {
    position: fixed;
    top: 0;
    margin: 0 0 0 0;
    padding: 0 0 0 0.25em;
    min-width: 100%;
    background-color: #0f70b7;
    min-height: 3.5em;
    z-index: 900;
}

#nagivator-wrap {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    min-height: 3.5em;
}

#hamburger-container {
    position: fixed;
    top: 0;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    min-width: 100%;
    min-height: 3.5em;
    max-height: 3.5em;
    z-index: 1000;
    overflow: hidden;
}

#nav-members-background {
    height: 40vh;
    min-width: 100%;
    float: right;
}

#hamburger {
    position: fixed;
    top: 0;
    margin: 0 0 0 0.5em;
    /* padding: 0.25em; */
    font-size: 2em;
    color: white;
    /* background-color: #0f70b7; */
    outline: 0;
}

#hamburger svg {
    vertical-align: middle;
}

#offline-msg {
    padding-top: 1em;
    color: red;
}

#search-button,
#search-shop-button {
    position: fixed;
    top: 0;
    right: 0;
    float: right;
    max-width: 6em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

.btn {
    color: white;
}

.btn:focus,
.btn:active {
    outline: none !important;
    box-shadow: none;
    border: none;
}

.searchbutton {
    font-size: 1.5em;
    width: 4em;
    min-height: 100%;
    max-height: 100%;
    margin: 0 0 0 0;
    padding: 0 0.75em 0 0;
    text-align: right;
    outline: none;
    border: none;
}

b-container {
    margin: 0 0 0 0;
    padding: 0.25em;
    width: 100%;
}

b-container .fluid {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
}

#menu-logo {
    width: 30vw;
    height: 30vw;
}

#menu-modal {
    max-width: 95%;
}

#menu-middle {
    padding: 0 0 0 0;
    margin-bottom: 1rem;
}

#menu-middle .px-0 {
    min-height: 35px;
    max-height: 35px;
}

#home-link,
#members-link,
#shops-link,
#noteshistory-link,
#visitationlog-link {
    padding: 0 0 0 0;
    color: black;
    text-decoration: none;
    outline: none;

}

#logout-link,
#signin-link {
    padding: 0 0 0 0;
    color: black;
    text-decoration: none;
    outline: none;
}

.icon-grid {
    min-width: 35px;
    max-width: 35px;
    min-height: 35px;
    max-height: 35px;
}

.icon-grid-png {
    padding: 3px;
}

.link-grid {
    min-height: 35px;
    max-height: 35px;
    text-align: center;
    padding-top: 0.35em;

}

.footer {
    position: fixed;
    bottom: 0;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    min-width: 100%;
    min-height: 1.5em;
    border-top: 1px solid white;
    z-index: 100;

}
</style>
